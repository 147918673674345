import messages from '../../../../locale/messages';
import { isEuropeCountry } from '../../../../helpers/europeCountryHelpers';

const validate = values => {

    const errors = {};

    if (!values.email) {
        errors.email = messages.required;
    } else if (!/^(([^<>()[\]\\.,;.!-#$&%*+/=?:{|}~-\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i.test(values.email)) {
        errors.email = messages.emailInvalid;
    }

    if (!values.country) {
        errors.country = messages.required;
    }

    if (!values.city) {
        errors.city = messages.required;
    }

    if (!values.state) {
        errors.state = messages.required;
    }

    if (!values.zipcode) {
        errors.zipcode = messages.required;
    }

    if (!values.firstname || (values.firstname && values.firstname.toString().trim() == '')) {
        errors.firstname = messages.required;
    } else if (values.firstname && values.firstname.length > 255) {
        errors.firstname = messages.exceedLimit255;
      }

    if (!values.lastname || (values.lastname && values.lastname.toString().trim() == '')) {
        errors.lastname = messages.required;
    }  else if (values.lastname && values.lastname.length > 255) {
        errors.lastname = messages.exceedLimit255;
      }


    if (!values.accountNumber) {
        errors.accountNumber = messages.required;
    } else if (values.accountNumber && values.accountNumber.toString().trim() === '') {
        errors.accountNumber = messages.accountNumberInvalid;
    }  else if (values.accountNumber && values.accountNumber.length > 255) {
        errors.accountNumber = messages.exceedLimit255;
      }


    if (!values.ibanNumber) {
        errors.ibanNumber = messages.required;
    } else if (values.ibanNumber && values.ibanNumber.toString().trim() === '') {
        errors.ibanNumber = messages.ibanNumberInvalid;
    }  else if (values.ibanNumber && values.ibanNumber.length > 50) {
        errors.ibanNumber = messages.exceedLimit255;
      }


   

   



    return errors;
};

export default validate;