import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import messages from '../../../locale/messages';
import { graphql } from 'react-apollo';
import Loader from '../../../components/Common/Loader/Loader';
import AdminEmailForm from '../../../components/SiteAdmin/AdminEmailForm/AdminEmailForm';
import { flowRight as compose } from 'lodash';
import adminEmailQuery from './adminEmailQuery.graphql';
import s from './AdminEmails.css';
import withStyles from 'isomorphic-style-loader/withStyles';

class AdminEmails extends Component {
    static defaultProps = {
        adminEmails: {
            loading: true
        }
    }

    render() {
        const { adminEmails: { loading, getAllAdminEmail } } = this.props;
        return (
            <Loader type={"page"} show={loading}>
                <div className={s.root}>
                    <div className={s.container}>
                        <div className={s.heading}>
                            <FormattedMessage {...messages.addAdminEmail} />
                        </div>
                        <div className={s.paddingRoutesSection}>
                            {
                                !loading && <AdminEmailForm getAllAdminEmail={getAllAdminEmail} />
                            }
                        </div>
                    </div>
                </div>
            </Loader>
        )
    }
}

export default compose(withStyles(s),
    graphql(adminEmailQuery, {
        name: 'adminEmails',
        options: (props) => ({
            fetchPolicy: 'network-only',
            ssr: false
        })
    }),
)(AdminEmails);
