import { addPayout } from '../../../../actions/shop/payout/addPayout';
import { toastr } from 'react-redux-toastr';

async function submit(values, dispatch) {

	let paymentType, payEmail, firstname, lastname, accountNumber, routingNumber, ssn4Digits, businessType, bankCode, branchCode, bankName;
	let branchName, accountOwnerName, transitNumber, institutionNumber, bsbNumber, sortCode, ibanNumber;



	paymentType = values.paymentType;
	// PayPal
	payEmail = paymentType == 5 ? values?.payEmail  : values?.email;
	// Stripe
	firstname = paymentType == 2 ? values?.firstname  : null;
	lastname = paymentType == 2 ? values?.lastname  : null;
	accountNumber = paymentType == 2 ? values?.accountNumber  : null;
	routingNumber = paymentType == 2 ? values?.routingNumber  : null;
	ssn4Digits = paymentType == 2 ? values?.ssn4Digits  : null;
	businessType = paymentType == 2 ? values?.businessType  : null;
	bankCode = paymentType == 2 ? values?.bankCode : null;
	branchCode = paymentType == 2 ? values?.branchCode  : null;
	bankName = paymentType == 2 ? values?.bankName  : null;
	branchName = paymentType == 2 ? values?.branchName  : null;
	accountOwnerName = paymentType == 2 ? values?.accountOwnerName  : null;
	transitNumber = paymentType == 2 ? values?.transitNumber  : null;
	institutionNumber = paymentType == 2 ? values?.institutionNumber : null;
	bsbNumber = paymentType == 2 ? values?.bsbNumber  : null;
	sortCode = paymentType == 2 ? values?.sortCode  : null;  
	ibanNumber = paymentType == 2 ? values?.ibanNumber  : null; 

	dispatch(
		addPayout(
			payEmail,
			values.address1,
			values.address2,
			values.city,
			values.state,
			values.country,
			values.zipcode,
			values.currency ? values.currency : "USD",
			firstname,
			lastname,
			accountNumber,
			routingNumber,
			ssn4Digits,
			businessType,
			bankCode,
			branchCode,
			bankName,
			branchName,
			accountOwnerName,
			transitNumber,
			institutionNumber,
			bsbNumber,
			sortCode,
			paymentType,
			ibanNumber
		)
	);

};

export default submit;
