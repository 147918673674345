import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';

// Redux
import { connect } from 'react-redux';

// Redux Form
import { Field, reduxForm } from 'redux-form';

import {
    Button,
    Form,
    Row,
    Col,
    Container
} from 'react-bootstrap';


import cx from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import s from '../Payout.css';
import bt from '../../../../components/commonStyle.css';
import rs from '../../../restaurantCommon.css';
import logourl from '../../../../../public/PaymentGateway/paypal.svg';

// Helpers
import validate from './validate';
import submit from './submit';

// Locale
import messages from '../../../../locale/messages';
import Loader from '../../../Common/Loader';

class Paypal extends Component {
    static propTypes = {
        handleSubmit: PropTypes.any.isRequired,
        previousPage: PropTypes.any.isRequired,
        siteName: PropTypes.string.isRequired,
        formatMessage: PropTypes.any,
    };


    renderField = ({ input, label, type, meta: { touched, error }, labelClass, fieldClass, placeholder }) => {
        const { formatMessage } = this.props.intl;
        return (
            <Form.Group className={cx(s.space3)}>
                <div className={cx(s.space2)}>
                    <Form.Label className={rs.restaurantLabelText}>{label}</Form.Label>
                </div>
                <div>
                    <Form.Control {...input} placeholder={placeholder} type={type} className={rs.formControlInputRestaurant} />
                    {touched && error && <span className={s.errorMessage}>{formatMessage(error)}</span>}
                </div>
            </Form.Group>
        )
    };

    render() {
        const { handleSubmit, pristine, previousPage, submitting, loading, error } = this.props;
        const { siteName } = this.props;
        const { formatMessage } = this.props.intl;

        return (
            <div className={'mainContainer'}>
                <div className={s.container}>
                    <Container fluid>
                        <Row>
                            <Col md={12} lg={12} sm={12} xs={12}>
                                <div>
                                    <h1 className={rs.restaurantTitleText}>
                                        <FormattedMessage {...messages.addPayout} />
                                    </h1>
                                </div>
                                <div>
                                    <img src={logourl} />
                                    <p className={cx(s.infoBox, s.space2, s.spaceTop2)}>
                                        <FormattedMessage {...messages.paypalIntro1} /> {siteName}.
                                        <FormattedMessage {...messages.paypalIntro2} /> {siteName}, <FormattedMessage {...messages.paypalIntro3} />
                                        {' '}<a href={"https://www.paypal.com/"} target="_blank" className={s.stripeLink}><FormattedMessage {...messages.paypalIntro4} /></a>
                                    </p>
                                </div>
                                <Form className={s.fullWidth} onSubmit={handleSubmit(submit)}>
                                    <div className={s.panelBody}>
                                        <Row>
                                            <Col lg={12} md={12} sm={12} xs={12}>
                                                <Field name="payEmail" component={this.renderField} label={formatMessage(messages.paypalEmail)} placeholder={formatMessage(messages.paypalEmail)} />
                                            </Col>
                                            <Col lg={12} md={12} sm={12} xs={12}>
                                                <div className={cx(s.infoBox, s.space2 )}>
                                                    {formatMessage(messages.payoutPaypalInfo)}
                                                </div>
                                            </Col>
                                            <Col lg={12} md={12} sm={12} xs={12} className={cx(s.spaceTop2, rs.alignRightText, 'textAlignLeftRTL')}>
                                                <div className={s.displayInlineBtn}>
                                                    <Form.Group className={s.formGroup}>
                                                        <div>
                                                            <Button onClick={previousPage} className={cx(rs.button, rs.btnPrimaryBorder, s.btnWidthMobile)}>
                                                                <FormattedMessage {...messages.back} />
                                                            </Button>
                                                        </div>
                                                    </Form.Group>
                                                </div>
                                                <div className={s.displayInlineBtn}>
                                                    <Form.Group className={s.formGroup}>
                                                        <div>
                                                            <Loader
                                                                type={'button'}
                                                                buttonType={'submit'}
                                                                className={cx(rs.button, rs.btnPrimary, s.btnWidthMobile, 'rtlBtnLoader')}
                                                                disabled={pristine || submitting || error}
                                                                show={loading}
                                                                label={formatMessage(messages.finish)}
                                                            />
                                                        </div>
                                                    </Form.Group>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </Form>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        );

    }
}

Paypal = reduxForm({
    form: 'PayoutForm', // a unique name for this form
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    validate
})(Paypal);

const mapState = (state) => ({
    siteName: state.siteSettings.data.siteName,
    loading: state.loader.AddPayoutForm
});

const mapDispatch = {};

export default injectIntl(withStyles(s, bt)(connect(mapState, mapDispatch)(Paypal)));