import React, { Component } from 'react'
import cx from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import PropTypes from 'prop-types';
import { Table, Row, Col } from 'react-bootstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
// styles
import s from './ViewReviewDetails.css';
// helpers
import messages from '../../../locale/messages';
import { capitalizeFirstLetter } from '../../../helpers/capitalizeFirst'
// Component
import StarRating from '../../StarRating';
import Link from '../../Link';

//Images
import like from '../../../../public/Icons/like.svg';
import dislike from '../../../../public/Icons/dislike.svg'

export class ViewReviewDetails extends Component {
    static propTypes = {
        title: PropTypes.string.isRequired,
    }

    constructor(props) {
        super(props);
        this.renderInformation = this.renderInformation.bind(this);
        this.renderUserInformation = this.renderUserInformation.bind(this);
    }

    renderInformation(label, information) {
        if (label && information) {
            return (
                <tr>
                    <td className={s.tableLabelColumn}><b>{label}</b></td>
                    <td>{information}</td>
                </tr>
            );
        }
    }

    renderUserInformation(label, information) {
        const { formatMessage } = this.props.intl;
        let userTypeLabel = {
            '1': formatMessage(messages.userLabel),
            '2': formatMessage(messages.driver),
            '3': formatMessage(messages.shopLabel)
        };

        if (label && information) {
            return (
                <tr>
                    <td className={s.tableLabelColumn}>
                        <b>{label}</b><br />
                        ({userTypeLabel[`${information.userType}`]})
                    </td>
                    <td>
                        {information.userType === 3 && capitalizeFirstLetter(information.shopName)}
                        {information.userType !== 3 && capitalizeFirstLetter(information.firstName)}<br />
                        {information.phoneNumber}<br />
                        {information.email}
                    </td>
                </tr>
            );
        }
    }

    render() {
        const { data, title } = this.props;
        const { formatMessage } = this.props.intl;
        const reviewData = data && data.viewReview && data.viewReview.result;

        let authorType, userType;
        if (reviewData) {
            authorType = reviewData?.authorData?.userType;
            userType = reviewData?.userData?.userType;
        }
        return (
            <div className={cx(s.pagecontentWrapper, s.widthInner, s.space5)}>
                <div className={s.contentBox}>
                    <Row>
                        <Col lg={8} md={8} sm={8} xs={12}>
                            <h1 className={cx(s.titleTextAdmin, s.space2)}> <FormattedMessage {...messages.viewReview} /></h1>
                        </Col>
                        <Col lg={4} md={4} sm={4} xs={12} className={cx(s.textAlignRight, s.space2, 'textAlignLeftRTL')}>
                            <Link to={'/siteadmin/reviews'} className={cx('pull-right', s.backBtn, s.backBtn, s.btnSecondary)}>
                                <FormattedMessage {...messages.goBack} />
                            </Link>
                        </Col>
                    </Row>
                    <div className={cx('table-responsive')}>
                        <Table>
                            {
                                reviewData && <tbody>
                                    {
                                        reviewData.bookingDetails && this.renderInformation(formatMessage(messages.orderId), `#${reviewData.bookingDetails.orderId}`)
                                    }
                                    {
                                        reviewData.authorData && this.renderUserInformation(formatMessage(messages.sender), reviewData.authorData)
                                    }
                                    {
                                        reviewData.userData && this.renderUserInformation(formatMessage(messages.receiver), reviewData.userData)
                                    }
                                    {
                                        (authorType != 1 || userType != 3) && reviewData?.ratings >= 0 && <tr>
                                            <td><FormattedMessage {...messages.ratings} /></td>
                                            <td>
                                                <StarRating
                                                    value={reviewData.ratings}
                                                    name={'review'}
                                                />
                                            </td>
                                        </tr>
                                    }
                                    {
                                        (authorType != 1 || userType != 3) && reviewData?.reviewContent && this.renderInformation(formatMessage(messages.reviewContent), reviewData?.reviewContent)
                                    }
                                    {
                                        authorType == 1 && userType == 3 && <tr>
                                            <td><FormattedMessage {...messages.ratings} /></td>
                                            <td><img src={reviewData?.ratings >= 1 ? like : dislike} width={25} /></td>
                                        </tr>
                                    }
                                    {
                                        authorType == 1 && userType == 3 && reviewData?.ratings == 0 && reviewData?.taste && <tr>
                                            <td><FormattedMessage {...messages.tasteLabel} /></td>
                                            <td>{reviewData?.taste}</td>
                                        </tr>
                                    }
                                    {
                                        authorType == 1 && userType == 3 && reviewData?.ratings == 0 && reviewData?.portionSize && <tr>
                                            <td><FormattedMessage {...messages.portionSize} /></td>
                                            <td>{reviewData?.portionSize}</td>
                                        </tr>
                                    }
                                    {
                                        authorType == 1 && userType == 3 && reviewData?.ratings == 0 && reviewData?.temperature && <tr>
                                            <td><FormattedMessage {...messages.temperature} /></td>
                                            <td>{reviewData?.temperature}</td>
                                        </tr>
                                    }
                                    {
                                        authorType == 1 && userType == 3 && reviewData?.ratings == 0 && reviewData?.presentation && <tr>
                                            <td><FormattedMessage {...messages.presentation} /></td>
                                            <td>{reviewData?.presentation}</td>
                                        </tr>
                                    }
                                </tbody>
                            }
                        </Table>
                    </div>
                </div>
            </div>
        )
    }
}

export default injectIntl(withStyles(s)(ViewReviewDetails));