import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import {
  Button,
  OverlayTrigger,
  Tooltip,
  Row,
  Col,
  Badge,
  Container
} from 'react-bootstrap';
import cx from 'classnames';
import * as FaInfoCircle from 'react-icons/lib/fa/info-circle';
import withStyles from 'isomorphic-style-loader/withStyles';
import s from '../Payout.css';
import rs from '../../../restaurantCommon.css';
import history from '../../../../history';
import messages from '../../../../locale/messages';
import { removePayout } from '../../../../actions/shop/payout/removePayout';
import { setDefaultPayout } from '../../../../actions/shop/payout/setDefaultPayout';
import { verifyPayout } from '../../../../actions/shop/payout/verifyPayout';
import { checkPayout } from '../../../../actions/shop/payout/checkPayout';

//Images
import TrashIcon from '../../../../../public/RestaurantIcon/dlty.png'

class PayoutList extends Component {

  constructor(props) {
    super(props)
    this.handleClick = this.handleClick.bind(this);
  };

  static defaultProps = {
    payoutRemoveLoader: false,
    payoutDefaultLoader: false,
    payoutVerifyLoader: false,
    data: []
  };

  handleClick() {
    const { checkPayout } = this.props;
    checkPayout();
  };

  render() {
    const { data, removePayout, setDefaultPayout, verifyPayout } = this.props;
    const { payoutRemoveLoader, payoutDefaultLoader, payoutVerifyLoader } = this.props;
    const { formatMessage } = this.props.intl;

    return (
      <div className={'mainContainer'}>
        <div className={s.container}>
          <Container fluid>
            <Row>
              <Col md={12} lg={12} sm={12} xs={12}>
                <div className={rs.titleInlineBlock}>
                  <h1 className={rs.restaurantTitleText}><FormattedMessage {...messages.payoutMethod} /></h1>
                </div>
                <div className={cx(s.spaceTop2, rs.topBtnScetion, 'floatLeftRTL', 'topBtnScetionMbRTL', 'floatinhertRTL')}>
                  <Button className={cx(rs.button, rs.btnPrimary)} onClick={this.handleClick}>
                    <FormattedMessage {...messages.addPayout} />
                  </Button>
                </div>
                <div className={s.panelBody}>
                  <p className={s.payoutIntro}>
                    <FormattedMessage {...messages.payoutTitleBlock1} />
                  </p>
                  <div className={cx(s.tableCss, 'tableCss', 'restauranttableSticky', 'restaurantTableCss', 'NewResponsiveTable')}>
                    <table className={cx('table', s.noBorder)}>
                      <thead>
                        <tr className={cx(s.rowBorder, s.sectionTitleLight, s.textTruncate)}>
                          <th className={s.noBorder} scope="col"><FormattedMessage {...messages.payoutTitle} /></th>
                          <th className={s.noBorder} scope="col"><FormattedMessage {...messages.payoutTitle4} /></th>
                          <th className={s.noBorder} scope="col"><FormattedMessage {...messages.status} /></th>
                          <th className={s.noBorder} scope="col"><FormattedMessage {...messages.options} /></th>
                          <th className={s.noBorder} scope="col"><FormattedMessage {...messages.action} /></th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          data.length > 0 && data.map((item, index) => {
                            return (

                              <tr className={cx(s.rowBorder, s.sectionTitleLight)} key={index}>
                                <td data-label={formatMessage(messages.payoutTitle)} className={'badgeSuccess'}>{item.paymentMethod.name} {item.default && <Badge variant="success"><FormattedMessage {...messages.default} /></Badge>}</td>
                                <td data-label={formatMessage(messages.payoutTitle4)}>
                                  <span>
                                    {item.payEmail && item.methodId === 2 ? (
                                      item.payEmail.length < 4 ? (
                                        item.payEmail
                                      ) : (
                                        '*'.repeat(item.payEmail.length - 4) + item.payEmail.slice(-4)
                                      )
                                    ) : (
                                      item.payEmail.toUpperCase()
                                    )}
                                  </span>
                                </td>
                                <td data-label={formatMessage(messages.status)}>
                                  {
                                    item.isVerified === true && <FormattedMessage {...messages.ready} />
                                  }
                                  {
                                    item.isVerified !== true && <FormattedMessage {...messages.notReady} />
                                  }
                                </td>
                                <td className={s.textTruncate} data-label={formatMessage(messages.options)}>
                                  {item.default && item.isVerified === true && <span className={s.lineThrough}><FormattedMessage {...messages.setDefault} /></span>}
                                  {
                                    !item.default && item.isVerified === true && <a
                                      href="javascript:void(0)"
                                      className={cx({ [s.transparentText]: payoutDefaultLoader })}
                                      onClick={() => {
                                        if (!payoutDefaultLoader) {
                                          setDefaultPayout(item.id)
                                        }
                                      }}
                                    >
                                      <FormattedMessage {...messages.setDefault} />
                                    </a>
                                  }
                                  {
                                    !item.default && item.isVerified !== true && <a
                                      href="javascript:void(0)"
                                      onClick={() => {
                                        if (!payoutVerifyLoader) {
                                          verifyPayout(item.payEmail, item.country);
                                        }
                                      }}
                                      title="Verify"
                                      className={s.veriyText}
                                    >
                                      <span className={rs.vtrMiddle}><FormattedMessage {...messages.payoutVerify} /></span>
                                      <OverlayTrigger
                                        overlay={<Tooltip id={'tooltip' + index}><FormattedMessage {...messages.payoutVerifyStripeInfo} /></Tooltip>}
                                        placement="top"
                                      >
                                        <span className={rs.vtrMiddle}>&nbsp;<FaInfoCircle style={{ color: '#5EBE00' }} /></span>
                                      </OverlayTrigger>
                                    </a>
                                  }
                                </td>
                                <td data-label={formatMessage(messages.action)}>
                                  {
                                    !item.default && <a
                                      className={cx(s.textSpace, { [s.transparentText]: payoutRemoveLoader }, s.removeText)}
                                      href="javascript:void(0)"
                                      onClick={() => {
                                        if (!payoutRemoveLoader) {
                                          removePayout(item.id);
                                        }
                                      }}
                                      title="Remove"
                                    >
                                      <span>
                                        <img src={TrashIcon} className={cx(s.iconsWidth, 'trashIconRTL')} />
                                      </span>
                                    </a>
                                  }
                                </td>
                              </tr>
                            );
                          })
                        }
                      </tbody>
                    </table>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    );
  }
};

const mapState = (state) => ({
  payoutRemoveLoader: state.loader.payoutRemove,
  payoutDefaultLoader: state.loader.payoutDefault,
  payoutVerifyLoader: state.loader.payoutVerify
});

const mapDispatch = {
  removePayout,
  setDefaultPayout,
  verifyPayout,
  checkPayout
};

export default injectIntl(withStyles(s, rs)(connect(mapState, mapDispatch)(PayoutList)));