import {
  OPEN_ADMIN_ROLES_MODAL,
  CLOSE_ADMIN_ROLES_MODAL,
  OPEN_ADMIN_USER_MODAL,
  CLOSE_ADMIN_USER_MODAL,
  UPDATE_PAYOUT_MODAL,
  OPEN_MANAGE_SERVICE_FEE_MODAL,
  CLOSE_MANAGE_SERVICE_FEE_MODAL
} from '../constants';

export default function adminModalStatus(state = {}, action) {
  switch (action.type) {

    case OPEN_ADMIN_ROLES_MODAL:
      return {
        ...state,
        adminRolesModal: action.payload.adminRolesModal,
        adminRolesModalType: action.payload.adminRolesModalType
      };

    case CLOSE_ADMIN_ROLES_MODAL:
      return {
        ...state,
        adminRolesModal: action.payload.adminRolesModal,
        adminRolesModalType: null
      };

    case OPEN_ADMIN_USER_MODAL:
      return {
        ...state,
        adminUserModal: action.payload.adminUserModal,
        adminUserModalType: action.payload.adminUserModalType
      };

    case CLOSE_ADMIN_USER_MODAL:
      return {
        ...state,
        adminUserModal: action.payload.adminUserModal,
        adminUserModalType: null
      };


    case UPDATE_PAYOUT_MODAL:
      return {
        ...state,
        isPayoutModal: action.payload.isPayoutModal,
        payoutData: action.payload.payoutData,
      };

      case OPEN_MANAGE_SERVICE_FEE_MODAL: {
        return {
          ...state,
          manageServiceFeeModal: action.manageServiceFeeModal
        }
      }
  
      case CLOSE_MANAGE_SERVICE_FEE_MODAL: {
        return {
          ...state,
          manageServiceFeeModal: action.manageServiceFeeModal
        }
      };

    default:
      return {
        ...state,
      };
  }
}
