import messages from '../../../locale/messages';


const validate = values => {
    const errors = {};

    if (values.feeType !== 'flatFee') {
        if (!values.dropFee && values.dropFee < 0) {
            errors.dropFee = messages.required;
        } else if (values.dropFee && parseInt(values.dropFee) && isNaN(values.dropFee)) {
            errors.dropFee = messages.invalidFee;
        } else if ((!/^[0-9\.]+$/.test(values.dropFee))) {
            errors.dropFee = messages.required;
        }

        if (!values.distanceFee && values.distanceFee < 0) {
            errors.distanceFee = messages.required;
        } else if (values.distanceFee && parseInt(values.distanceFee) && isNaN(values.distanceFee)) {
            errors.distanceFee = messages.invalidFee;
        } else if ((!/^[0-9\.]+$/.test(values.distanceFee))) {
            errors.distanceFee = messages.required;
        }
    }

    if (!values.deliveryFee && values.deliveryFee < 0) {
        errors.deliveryFee = messages.required;
    } else if (values.deliveryFee && parseInt(values.deliveryFee) && isNaN(values.deliveryFee)) {
        errors.deliveryFee = messages.invalidFee;
    } else if ((!/^[0-9\.]+$/.test(values.deliveryFee))) {
        errors.deliveryFee = messages.required;
    }

    if(!values.bookingFee && values.bookingFee < 0) {
        errors.bookingFee = messages.required;
    }  else if(values.bookingFee && (parseFloat(values.bookingFee, 10) < 0) && isNaN(values.bookingFee)){
        errors.bookingFee = messages.invalidFee;
    }else if((!/^[0-9\.]+$/.test(values.bookingFee))){
        errors.bookingFee = messages.required;
    }

    if(!values.shopFee && values.shopFee < 0) {
        errors.shopFee = messages.required;
    } else if(values.shopFee && (parseFloat(values.shopFee, 10) < 0) && isNaN(values.shopFee)){
        errors.shopFee = messages.invalidFee;
    } else if((!/^[0-9\.]+$/.test(values.shopFee))){
        errors.shopFee = messages.required;
    }

    return errors;
}
export default validate;