/*

Supported languages

English - en
Spanish - es
French - fr
Russian - ru
Japanese - ja	
Indonesian - id

*/

export function bookingStatus(status, lang) {
    let statusLabelSpec = {}, requestLang = lang || 'en-US';

    if (status === 'pending') {
        statusLabelSpec = {
            'en-US': 'PENDING',
            'es': 'PENDIENTE',
            'fr-FR': 'EN ATTENTE',
            'ru-RU': 'ОЖИДАНИЕ',
            'ja-JA': '保留中',
            'id-ID': 'TERTUNDA',
            'ar': 'ريثما'
        };
    }

    if (status === 'cancelledByUser') {
        statusLabelSpec = {
            'en-US': 'CANCELLED',
            'es': 'CANCELADO',
            'fr-FR': 'ANNULÉ',
            'ru-RU': 'ОТМЕНЕН',
            'ja-JA': 'キャンセル',
            'id-ID': 'DIBATALKAN',
            'ar': 'ألغيت'
        };
    }

    if (status === 'approved') {
        statusLabelSpec = {
            'en-US': 'CONFIRMED',
            'es': 'CONFIRMADO',
            'fr-FR': 'CONFIRMÉ',
            'ru-RU': 'ПОДТВЕРЖДЕНО',
            'ja-JA': '確認済み',
            'id-ID': 'DIKONFIRMASI',
            'ar': 'تم تأكيد'
        };
    }

    if (status === 'declined') {
        statusLabelSpec = {
            'en-US': 'DECLINED',
            'es': 'DECLINADO',
            'fr-FR': 'DIMINUÉ',
            'ru-RU': 'ОТКЛОНЕН',
            'ja-JA': '辞退',
            'id-ID': 'DITOLAK',
            'ar': 'رفض'
        };
    }

    if (status === 'expired') {
        statusLabelSpec = {
            'en-US': 'DECLINED',
            'es': 'DECLINADO',
            'fr-FR': 'DIMINUÉ',
            'ru-RU': 'ОТКЛОНЕН',
            'ja-JA': '辞退',
            'id-ID': 'DITOLAK',
            'ar': 'رفض'
        };
    }

    if (status === 'cancelledByPartner') {
        statusLabelSpec = {
            'en-US': 'CANCELLED BY RESTAURANT',
            'es': 'CANCELADO POR RESTAURANTE',
            'fr-FR': 'ANNULÉ PAR LE RESTAURANT',
            'ru-RU': 'ОТМЕНЕНО РЕСТОРАНОМ',
            'ja-JA': 'レストランによってキャンセルされました',
            'id-ID': 'DIBATALKAN OLEH RESTAURANT',
            'ar': 'تم الإلغاء من قبل المطعم'
        };
    }

    if (status === 'readyForDelivery') {
        statusLabelSpec = {
            'en-US': 'READY FOR PICKUP',
            'es': 'LISTO PARA RECOGER',
            'fr-FR': 'PRÊT POUR LE RAMASSAGE',
            'ru-RU': 'ГОТОВ',
            'ja-JA': 'ピックアップの準備ができて',
            'id-ID': 'SIAP UNTUK PICKUP',
            'ar': 'جاهز للإستلام'
        };
    }

    if (status === 'deliveryNotFound') {
        statusLabelSpec = {
            'en-US': 'DELIVERY FAILED',
            'es': 'ENTREGA FALLIDA',
            'fr-FR': 'LIVRAISON ÉCHOUÉE',
            'ru-RU': 'ДОСТАВКА НЕ УДАЛАСЬ',
            'ja-JA': '配信に失敗しました',
            'id-ID': 'PENGIRIMAN GAGAL',
            'ar': 'فشل التسليم'
        };
    }

    if (status === 'deliveryApproved') {
        statusLabelSpec = {
            'en-US': 'DELIVERY CONFIRMED',
            'es': 'ENTREGA CONFIRMADA',
            'fr-FR': 'LIVRAISON CONFIRMÉ',
            'ru-RU': 'ДОСТАВКА ПОДТВЕРЖДЕНА',
            'ja-JA': '配達が確認されました',
            'id-ID': 'PENGIRIMAN DIKONFIRMASI',
            'ar': 'تم تأكيد التسليم'
        };
    }

    if (status === 'deliveryDeclined') {
        statusLabelSpec = {
            'en-US': 'DELIVERY DECLINED',
            'es': 'ENTREGA RECHAZADA',
            'fr-FR': 'LIVRAISON REFUSÉE',
            'ru-RU': 'ДОСТАВКА ОТКЛОНЕНА',
            'ja-JA': '配達が拒否されました',
            'id-ID': 'PENGIRIMAN DITOLAK',
            'ar': 'التسليم مرفوض'
        };
    }

    if (status === 'cancelledByDelivery') {
        statusLabelSpec = {
            'en-US': 'DELIVERY CANCELLED',
            'es': 'ENTREGA CANCELADA',
            'fr-FR': 'LIVRAISON ANNULÉ',
            'ru-RU': 'ДОСТАВКА ОТМЕНЕНА',
            'ja-JA': '配達がキャンセルされました',
            'id-ID': 'PENGIRIMAN DIBATALKAN',
            'ar': 'تم إلغاء التسليم'
        };
    }

    if (status === 'deliveryConfirmed') {
        statusLabelSpec = {
            'en-US': 'DELIVERY PICKED UP',
            'es': 'ENTREGA RECOGIDO',
            'fr-FR': 'LIVRAISON RAMASSÉS',
            'ru-RU': 'ДОСТАВКА ЗАБРАНА',
            'ja-JA': '配達物を受け取りました',
            'id-ID': 'PENGIRIMAN DIAMBIL',
            'ar': 'انتقاء التسليم'

        };

    }

    if (status === 'startDelivery') {
        statusLabelSpec = {
            'en-US': 'DELIVERY IN PROGRESS',
            'es': 'ENTREGA EN CURSO',
            'fr-FR': 'LIVRAISON EN COURS',
            'ru-RU': 'ДОСТАВКА В ПРОЦЕССЕ',
            'ja-JA': '進行中の配信',
            'id-ID': 'PENGIRIMAN DALAM PROGRESS',
            'ar': 'التسليم قيد التقدم'
        };
    }

    if (status === 'completed') {
        statusLabelSpec = {
            'en-US': 'DELIVERED',
            'es': 'ENTREGADO',
            'fr-FR': 'LIVRÉ',
            'ru-RU': 'ДОСТАВЛЕН',
            'ja-JA': '配信',
            'id-ID': 'TERSIRAT',
            'ar': 'تم التوصيل'
        };
    }

    if (status === 'failed') {
        statusLabelSpec = {
            'en-US': 'UNABLE TO DELIVERED',
            'es': 'INCAPAZ DE ENTREGA',
            'fr-FR': 'IMPOSSIBLE DE LIVRER',
            'ru-RU': 'НЕВОЗМОЖНО ДОСТАВИТЬ',
            'ja-JA': '配信できません',
            'id-ID': 'TIDAK DAPAT DIBERIKAN',
            'ar': 'غير قادر على التسليم'
        };
    }

    if (status === 'pickUpRequest') {
        statusLabelSpec = {
            'en-US': 'DELIVERY DONE',
            'es': 'ENTREGA HECHO',
            'fr-FR': 'LIVRAISON EFFECTUÉE',
            'ru-RU': 'ДОСТАВКА ГОТОВА',
            'ja-JA': '配達完了',
            'id-ID': 'PENGIRIMAN SELESAI',
            'ar': 'تم التسليم'
        };
    }

    return status ? (statusLabelSpec[requestLang] ? statusLabelSpec[requestLang] : statusLabelSpec['en-US']) : '';
}

export function originalBookingStatus(status) {
    const bookingStatus = [
        ['PENDING', 'PENDIENTE', 'EN ATTENTE', 'ОЖИДАНИЕ', '保留中', 'TERTUNDA', 'ريثما'],
        ['CANCELLED', 'CANCELADO', 'ANNULÉ', 'ОТМЕНЕН', 'キャンセル', 'DIBATALKAN', 'ألغيت'],
        ['CONFIRMED', 'CONFIRMADO', 'CONFIRMÉ', 'ПОДТВЕРЖДЕНО', '確認済み', 'DIKONFIRMASI', 'تم تأكيد'],
        ['DECLINED', 'DECLINADO', 'DIMINUÉ', 'ОТКЛОНЕН', '辞退', 'DITOLAK', 'رفض'],
        ['CANCELLED BY RESTAURANT', 'CANCELADO POR RESTAURANTE', 'ANNULÉ PAR LE RESTAURANT', 'ОТМЕНЕНО РЕСТОРАНОМ', 'レストランによってキャンセルされました', 'DIBATALKAN OLEH RESTAURANT', 'تم الإلغاء من قبل المطعم'],
        ['READY FOR PICKUP', 'LISTO PARA RECOGER', 'PRÊT POUR LE RAMASSAGE', 'ГОТОВ', 'ピックアップの準備ができて', 'SIAP UNTUK PICKUP', 'جاهز للإستلام'],
        ['DELIVERY FAILED', 'ENTREGA FALLIDA', 'LIVRAISON ÉCHOUÉE', 'ДОСТАВКА НЕ УДАЛАСЬ', '配信に失敗しました', 'PENGIRIMAN GAGAL', 'فشل التسليم'],
        ['DELIVERY CONFIRMED', 'ENTREGA CONFIRMADA', 'LIVRAISON CONFIRMÉ', 'ДОСТАВКА ПОДТВЕРЖДЕНА', '配達が確認されました', 'PENGIRIMAN DIKONFIRMASI', 'تم تأكيد التسليم'],
        ['DELIVERY DECLINED', 'ENTREGA RECHAZADA', 'LIVRAISON REFUSÉE', 'ДОСТАВКА ОТКЛОНЕНА', '配達が拒否されました', 'PENGIRIMAN DITOLAK', 'التسليم مرفوض'],
        ['DELIVERY CANCELLED', 'ENTREGA CANCELADA', 'LIVRAISON ANNULÉ', 'ДОСТАВКА ОТМЕНЕНА', '配達がキャンセルされました', 'PENGIRIMAN DIBATALKAN', 'تم إلغاء التسليم'],
        ['DELIVERY PICKED UP', 'ENTREGA RECOGIDO', 'LIVRAISON RAMASSÉS', 'ДОСТАВКА ЗАБРАНА', '配達物を受け取りました', 'PENGIRIMAN DIAMBIL', 'انتقاء التسليم'],
        ['DELIVERY IN PROGRESS', 'ENTREGA EN CURSO', 'LIVRAISON EN COURS', 'ДОСТАВКА В ПРОЦЕССЕ', '進行中の配信', 'PENGIRIMAN DALAM PROGRESS', 'التسليم قيد التقدم'],
        ['DELIVERED', 'ENTREGADO', 'LIVRÉ', 'ДОСТАВЛЕН', '配信', 'TERSIRAT', 'تم التوصيل'],
        ['UNABLE TO DELIVERED', 'INCAPAZ DE ENTREGA', 'IMPOSSIBLE DE LIVRER', 'НЕВОЗМОЖНО ДОСТАВИТЬ', '配信できません', 'TIDAK DAPAT DIBERIKAN', 'غير قادر على التسليم'],
        ['DELIVERY DONE', 'ENTREGA HECHO', 'LIVRAISON EFFECTUÉE', 'ДОСТАВКА ГОТОВА', '配達完了', 'PENGIRIMAN SELESAI', 'تم التسليم'],
    ]

    const originalStatusObj = {
        0: "pending",
        1: "cancelledByUser",
        2: "approved",
        3: "declined", // declined or expired
        4: "cancelledByPartner",
        5: "readyForDelivery",
        6: "deliveryNotFound",
        7: "deliveryApproved",
        8: "deliveryDeclined",
        9: "cancelledByDelivery",
        10: "deliveryConfirmed",
        11: "startDelivery",
        12: "completed",
        13: "failed",
        14: "pickUpRequest",
    }

    const findBookingStatus = bookingStatus.findIndex(statusValue => statusValue.includes(status));

    return findBookingStatus >= 0 ? originalStatusObj[findBookingStatus] : null;
}