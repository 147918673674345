
import React from 'react';
import { defineMessages } from 'react-intl';
import Layout from '../../../components/Layout/HeadLessLayout';
import Login from './Login';
import messages from '../../../locale/messages';

// const messages = defineMessages({
//   title: {
//     id: 'login.title',
//     description: 'Log in page title',
//     defaultMessage: 'Log In',
//   },
// });

function action({ intl, store, query }) {
  const title = intl.formatMessage(messages.login);

  // From Redux Store
  let isAdminAuthenticated = store.getState().runtime.isAdminAuthenticated;
  let refer = query.isdrivers || query.isrestaurant;
  let redirectUrl;

  if ('isdrivers' in query) {
    redirectUrl = '/siteadmin/drivers'
  }
  if ('isrestaurant' in query) {
    redirectUrl = '/siteadmin/restaurant/list'
  }

  if (isAdminAuthenticated) {
    return { redirect: '/siteadmin' };
  }

  return {
    title,
    component: (
      <Layout>
        <Login title={title} refer={refer} url={redirectUrl} />
      </Layout>
    ),
  };
}

export default action;
