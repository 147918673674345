
import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import s from './Login.css';

import AdminLoginForm from '../../../components/SiteAdmin/AdminLoginForm';

class Login extends React.Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
  };

  render() {
    const { refer, url } = this.props;
    let initialValues = {};
    
    if (refer) {
      initialValues = { refer, url };
    }

    return (
      <div>
        <AdminLoginForm initialValues={initialValues} />
      </div>
    );
  }
}

export default withStyles(s)(Login);