
import gql from 'graphql-tag';
import { destroy } from 'redux-form';
import history from '../../../history';
import { toastr } from 'react-redux-toastr';
import {
  ADD_PAYOUT_START,
  ADD_PAYOUT_SUCCESS,
  ADD_PAYOUT_ERROR,
} from '../../../constants';
import { processStripePayment } from '../../../core/payment/stripe/processStripePayment';
import { setLoaderComplete, setLoaderStart } from '../../loader/loader';
import checkPaymentMethod from '../../../components/Shop/Payout/PayoutForm/getPaymentMethods.graphql';
import { getPayouts } from './getPayouts';

export function addPayout(
  payEmail,
  address1,
  address2,
  city,
  state,
  country,
  zipcode,
  currency,
  firstname,
  lastname,
  accountNumber,
  routingNumber,
  ssn4Digits,
  businessType,
  bankCode,
  branchCode,
  bankName,
  branchName,
  accountOwnerName,
  transitNumber,
  institutionNumber,
  bsbNumber,
  sortCode,
  methodId,
  ibanNumber) {

  return async (dispatch, getState, { client }) => {

    dispatch({
      type: ADD_PAYOUT_START
    });


    try {

      const { data } = await client.query({
        query: checkPaymentMethod,
        fetchPolicy: 'network-only'
      });

      if (data && data.getPaymentMethods && data.getPaymentMethods.length > 0) {
        if (!data.getPaymentMethods[0].isEnable) {
          dispatch({
            type: ADD_PAYOUT_ERROR,
          });
          toastr.warning('Error!', 'This feature is currently not available. Please contact admin for more information.');
          return;
        }
      };

      dispatch(setLoaderStart('AddPayoutForm'));

      let mutation = gql`
      mutation addPayout(
        $methodId: Int!, 
        $payEmail: String!,
        $address1: String,
        $address2: String,
        $city: String!,
        $state: String!,
        $country: String!,
        $zipcode: String!,
        $currency: String!,
        $last4Digits: Int,
        $isVerified: Boolean,
        $ibanNumber: String,
        $firstname: String,
        $lastname: String,
      ){
          addPayout(
            methodId: $methodId,
            payEmail: $payEmail,
            address1: $address1,
            address2: $address2,
            city: $city,
            state: $state,
            country: $country,
            zipcode: $zipcode,
            currency: $currency,
            last4Digits: $last4Digits,
            isVerified: $isVerified,
            ibanNumber: $ibanNumber,
            firstname: $firstname
            lastname: $lastname
          ) {
              id
              methodId
              userId
              payEmail
              last4Digits
              address1
              address2
              city
              state
              country
              zipcode
              currency
              createdAt
              status
          }
      }
  `;

      if (methodId == 5) { // PayPal
        const { data } = await client.mutate({
          mutation,
          variables: {
            methodId,
            payEmail,
            address1,
            address2,
            city,
            state,
            country,
            zipcode,
            currency,
            isVerified: true
          }
        });

        await dispatch(getPayouts());

        if (data && data.addPayout) {
          await dispatch({
            type: ADD_PAYOUT_SUCCESS,
            payload: {
              status: data.addPayout.status,
              payoutLoading: false
            }
          });
          dispatch(setLoaderComplete('AddPayoutForm'));
          await dispatch(destroy('PayoutForm'));
          history.push('/restaurant/payout');
        }
        dispatch(setLoaderComplete('AddPayoutForm'));
      }
      else { // Stripe

      let userDetails = {
        payEmail
      };

      let bankDetails = {
        payEmail,
        address1,
        address2,
        city,
        state,
        country,
        zipcode,
        currency,
        firstname,
        lastname,
        accountNumber,
        routingNumber,
        ssn4Digits,
        businessType,
        bankCode,
        branchCode,
        bankName,
        branchName,
        accountOwnerName,
        transitNumber,
        institutionNumber,
        bsbNumber,
        sortCode
      };

    

      const { data } = await client.mutate({
        mutation,
        variables: {
          methodId,
          payEmail : accountNumber,
          address1,
          address2,
          city,
          state,
          country,
          zipcode,
          currency,
          isVerified: true,
          ibanNumber,
          firstname,
          lastname
        }
      });

      await dispatch(getPayouts());

      if (data && data.addPayout) {
        await dispatch({
          type: ADD_PAYOUT_SUCCESS,
          payload: {
            status: data.addPayout.status,
            payoutLoading: false
          }
        });
        dispatch(setLoaderComplete('AddPayoutForm'));
        await dispatch(destroy('PayoutForm'));
        history.push('/restaurant/payout');
      }
      dispatch(setLoaderComplete('AddPayoutForm'));

    }

    } catch (error) {
  
      dispatch({
        type: ADD_PAYOUT_ERROR
      });

      dispatch(setLoaderComplete('AddPayoutForm'));

    }
  };
};