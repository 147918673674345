import React, { Component } from 'react';
import { connect } from 'react-redux';
import Stripe from './Stripe';
import { formValueSelector } from 'redux-form';
import Paypal from './Paypal';

class PayoutConfirm extends Component {

  render() {
    const { previousPage, paymentType } = this.props;

    if (paymentType === 2) {
      return (
        <Stripe
          previousPage={previousPage}
        />
      );
    } else {
      return <Paypal
        previousPage={previousPage}
      />
    }

  }
};

const selector = formValueSelector('PayoutForm');

const mapState = (state) => ({
  paymentType: selector(state, 'paymentType')
});

const mapDispatch = {};

export default connect(mapState, mapDispatch)(PayoutConfirm);