require('dotenv').config();
/* eslint-disable max-len */


module.exports = {
  // default locale is the first one
  locales: [
    /* @intl-code-template '${lang}-${COUNTRY}', */
    'en-US',
    'es',
    'fr-FR',
    'id-ID',
    'ja-JA',
    'ru-RU',
    'ar'
    /* @intl-code-template-end */
  ],

  // Node.js app
  port: process.env.PORT,

  // https://expressjs.com/en/guide/behind-proxies.html
  trustProxy: process.env.TRUST_PROXY || 'loopback',

  // SITE URL
  siteUrl: process.env.SITE_URL || 'https://www.ishuttlefood.com',

  // API Gateway
  api: {
    // API URL to be used in the client-side code
    clientUrl: process.env.API_CLIENT_URL || '',
    // API URL to be used in the server-side code
    serverUrl:
      process.env.API_SERVER_URL ||
      `http://localhost:${process.env.PORT || 3000}`,

    apiEndpoint: process.env.API_ENDPOINT_URL || 'https://www.ishuttlefood.com/api'
  },

  //Socket URL
  socketUrl: process.env.SOCKET_URL || 'https://www.ishuttlefood.com',

  // Database
  databaseUrl: process.env.DATABASE_URL,

  // CRONs Timezone
  cronTimezone: process.env.CRON_TIMEZONE,
  // Refer this link for valid timezone https://raw.githubusercontent.com/node-cron/tz-offset/master/generated/offsets.json

  // Licence Upload
  licenseuploadDir: process.env.LICENSE_UPLOAD_DIR || '/images/license/',

  // Vehicle Upload
  vehicleUploadDir: process.env.VEHICLE_UPLOAD_DIR || '/images/vehicle/',

  // Profile photo upload
  profilePhotouploadDir: process.env.PROFILE_PHOTO_UPLOAD_DIR || '/images/avatar/',

  // category photo upload
  categoryUploadDir: process.env.CATEGORY_PHOTO_UPLOAD_DIR || '/images/category/',

  // Logo photo upload
  logoUploadDir: process.env.LOGO_PHOTO_UPLOAD_DIR || '/images/logo/',

  // homepage photo upload
  homepageUploadDir: process.env.HOMEPAGE_UPLOAD_DIR || '/images/homepage/',

  // staticpage photo upload
  staticpageUploadDir: process.env.STATICPAGE_UPLOAD_DIR || '/images/staticpage/',

  // Delivery Vehicle Upload
  deliveryVehicleUploadDir: process.env.DELIVERY_VEHICLE_UPLOAD_DIR || '/images/delivery-vehicle/',

  //contentpage photo upload 
  contentPageUploadDir: process.env.CONTENTPAGE_UPLOAD_DIR || '/images/contentpage/',

  //RestaurantImage photo upload 
  restaurantImageUploadDir: process.env.RESTAURANT_IMAGE_UPLOAD_DIR || '/images/restaurant-image/',

  //RestaurantImage photo upload 
  restaurantDocumentUploadDir: process.env.RESTAURANT_DOCUMENT_UPLOAD_DIR || '/images/restaurant-document/',

  //Partner Home page Image photo upload 
  partnerHomepageUploadDir: process.env.PARTNER_HOMEPAGE_UPLOAD_DIR || '/images/partner-homepage/',

  // Restaurant Item Image
  itemImageUploadDir: process.env.ITEM_IMAGE_UPLOAD_DIR || '/images/restaurant-itemImage/',

  // favicon upload
  faviconUploadDir: process.env.FAVICON_UPLOAD_DIR || '/images/favicon/',

  //Order alert tone uplode
  toneUploadDir:  process.env.TONE_UPLOAD_DIR || '/images/tone/',

  //Google map api key
  //googleMapAPI: 'AIzaSyCZF3YS8HA3M-WtAORB9HkQFsCUKdBpx80',
  googleMapAPI: 'AIzaSyAhEjv0NKDfFFBPum6Ut1IuoPGAU0gJV2I',

  // Web analytics
  analytics: {
    // https://analytics.google.com/
    googleTrackingId: process.env.GOOGLE_TRACKING_ID || 'UA-XXXXX-X', // UA-XXXXX-X
  },

  // Payment - Stripe
  payment: { /* From ENV */
    stripe: {
      secretKey: process.env.STRIPE_SECRET
    }
  },

  // Authentication
  auth: {
    jwt: { secret: process.env.JWT_SECRET },

    // https://developers.facebook.com/
    facebook: {
      id: process.env.FACEBOOK_APP_ID,
      secret: process.env.FACEBOOK_APP_SECRET,
    },

    // https://cloud.google.com/console/project
    google: {
      id: process.env.GOOGLE_CLIENT_ID,
      secret: process.env.GOOGLE_CLIENT_SECRET
    },

    // https://apps.twitter.com/
    twitter: {
      key: process.env.TWITTER_CONSUMER_KEY,
      secret: process.env.TWITTER_CONSUMER_SECRET,
    },
  },
 dataEncrytionSecret : process.env.DATA_ENCRYTIONSECRET || "Wooberly",
 adminEmail : "recoore@gmail.com",
 adminEmailList: ['recoore@gmail.com'], 
 emailCronTimeZone: process.env.EMAIL_CRON_TIMEZONE || 'Jamaica'
};

