import messages from '../../../locale/messages';

const validate = values => {
    const errors = {}
    if (!values.email || values.email.length === 0) {
        errors.email = messages.required
    }
    return errors;
}

export default validate;