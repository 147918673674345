import gql from 'graphql-tag';
import { toastr } from 'react-redux-toastr';
import getAllShopsQuery from './getAllShops.graphql';

export function updateAdminServiceFee(values, dispatch) {

    let currentPage, searchList, deliveryType;
    currentPage = values.currentPage;
    searchList = values?.searchList ? values?.searchList : "";
    deliveryType = values.deliveryType;

    return async (dispatch, getState, { client }) => {
        try {
            const mutation = gql`
            mutation updateAdminServiceFee (
            $userId: String,     
            $feeType: String,
            $deliveryFee: Float,
            $dropFee: Float,
            $distanceFee: Float,
            $bookingFee: Int,
            $shopFee: Int,
            $deliveryMode: String
        ) {
            updateAdminServiceFee (
                userId: $userId
                feeType: $feeType
                deliveryFee: $deliveryFee,
                dropFee: $dropFee,
                distanceFee: $distanceFee
                bookingFee: $bookingFee,
                shopFee: $shopFee,
                deliveryMode: $deliveryMode
            ) {
                status
                errorMessage
            }
        }
    `;
            const { data } = await client.mutate({
                mutation,
                variables: {
                    userId: values.userId,
                    feeType: values.feeType,
                    deliveryFee: values.deliveryFee,
                    dropFee: values.dropFee,
                    distanceFee: values.distanceFee,
                    bookingFee: values.bookingFee,
                    shopFee: values.shopFee,
                    deliveryMode: values.deliveryMode
                },
                refetchQueries: [{ query: getAllShopsQuery, variables: { currentPage, searchList } }]
            });

            if (data?.updateAdminServiceFee?.status === 200) {
                toastr.success("Success", "Service Fee Updated!")
            } else {
                toastr.error("Failed", "Update Service Fee Failed")
            }
        } catch (error) {
            console.log('Something went wrong ' + error)
        }
    };
}