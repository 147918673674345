
import React from 'react';
import AddPayoutContainer from './AddPayoutContainer';
import { getPayouts } from '../../../../actions/shop/payout/getPayouts';
import DashboardLayout from '../../../../components/Shop/RestaurantLayout/Layout/DashboardLayout';
import messages from '../../../../locale/messages';

async function action({ store, query, intl }) {
  const title = intl.formatMessage(messages.payoutPreferences);

  let isRestaurantAuthenticated = store.getState().runtime.isRestaurantAuthenticated;
  let initialData;

  if (!isRestaurantAuthenticated) {
    return { redirect: '/restaurant' };
  }

  let accountData = store.getState().account.data;

  if (accountData) {
    initialData = {
      country: accountData && accountData.profile && accountData.profile.country,
      email: accountData.email,
      paymentType :2
    };
  }

  return {
    title,
    component: (
      <DashboardLayout>
        <AddPayoutContainer title={title} initialData={initialData} />
      </DashboardLayout>
    )
  };
};

export default action;
