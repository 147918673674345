// General
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Style
import withStyles from 'isomorphic-style-loader/withStyles';
import cx from 'classnames';
import s from './ManageServiceFeeModal.css';
import {
    Modal
} from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

import messages from '../../../locale/messages'

// Redux
import { connect } from 'react-redux';
import { closeManageServiceFeeModal } from '../../../actions/siteadmin/modalActions';

import { graphql } from 'react-apollo';
import { flowRight as compose } from 'lodash';

import getServiceFee from './getServiceFee.graphql';

//Component
import ManageRestaurantServiceFee from './ManageRestaurantServiceFee';

class ManageServiceFeeModal extends Component {
    static propTypes = {
        closeManageServiceFeeModal: PropTypes.any,
    };

    constructor(props) {
        super(props);
        this.state = {
            manageServiceFeeModalStatus: false
        }
    }

    componentDidMount() {
        const { manageServiceFeeModalStatusInfo } = this.props;
        if (manageServiceFeeModalStatusInfo === true) {
            this.setState({ manageServiceFeeModalStatus: true });
        }
    }

    componentWillReceiveProps(nextProps) {
        const { manageServiceFeeModalStatusInfo } = nextProps;
        if (manageServiceFeeModalStatusInfo === true) {
            this.setState({ manageServiceFeeModalStatus: true });
        } else {
            this.setState({ manageServiceFeeModalStatus: false });
        }
    }

    render() {
        const { closeManageServiceFeeModal, currentPage, searchList, deliveryType } = this.props;
        const { manageServiceFeeModalStatus } = this.state;
        const { serviceFee: { getServiceFee, loading } } = this.props;
        const { userId, deliveryMode } = this.props;
        const { adminServiceFee } = this.props;
        let initialValues = !adminServiceFee ? getServiceFee : adminServiceFee;

        return (
            <div>
                <Modal show={manageServiceFeeModalStatus} onHide={closeManageServiceFeeModal}>
                    <Modal.Header closeButton>
                        <Modal.Title> <FormattedMessage {...messages.manageServiceFee} /> </Modal.Title>
                    </Modal.Header>
                    <Modal.Body bsClass={s.logInModalBody}>
                        <div className={cx(s.modalRoot, s.modalBorderBottom)}>
                            <ManageRestaurantServiceFee
                                userId={userId}
                                currentPage={currentPage}
                                searchList={searchList}
                                // deliveryType={deliveryType}
                                deliveryMode={deliveryMode}
                                initialValues={initialValues}
                                isModalPopup={true}
                                closeManageServiceFeeModal={closeManageServiceFeeModal} />
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        )
    }
}

const mapState = (state) => ({
    manageServiceFeeModalStatusInfo: state.adminModalStatus.manageServiceFeeModal
});

const mapDispatch = {
    closeManageServiceFeeModal,
};

export default compose(
    withStyles(s),
    connect(mapState, mapDispatch),
    graphql(getServiceFee, {
        name: 'serviceFee',
        options: {
            ssr: true,
            fetchPolicy: 'network-only'
        }
    })
)(ManageServiceFeeModal);